import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { SlotId } from '../config';
import { Game } from '../game';
import { EventTypes, GameMode, ISettledBet, reelSets } from '../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenBuyFeature,
  setBrokenGame,
  setCascadeWins,
  setCascades,
  setCoinAmount,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setHistoryReplayBet,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringWinCountUpAnimation,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsTimeoutErrorMessage,
  setLastRegularHistory,
  setLastRegularWinAmount,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig, ReelSetType } from '../gql/d';
import { isStoppedGql, slotBetGql } from '../gql/query';
import {
  getFreeSpinBonus,
  getGameModeByBonusId,
  getGameModeByReelSetId,
  getSlotPerAmount,
  getSpinResult,
  getSymbolMatrixFromIcons,
  getSymbolMatrixFromSymbols,
  getWinStage,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinMode,
  isRegularMode,
  lotteryPhoenix,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';
import { getCascadeWins, getCascadedSymbolMatrix, getCascades, getMultiplierMatrix } from '../utils/cascade';

import Animation from './animations/animation';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import { layerUI } from './components/layer/layer';
import {
  BASE_GAME_SLOTS_PER_REEL_AMOUNT,
  FREE_SPINS_TIME_OUT_BANNER,
  GAME_VIEW_HIT_AREA,
  REELS_AMOUNT,
  SlotMachineState,
  WinStages,
  eventManager,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuButton from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import { FreeRoundBonusController } from './freeRoundBonus/freeRoundBonusController';
import { FreeRoundsPopup } from './freeRoundBonus/freeRoundsPopup';
import { FreeRoundsEndPopup } from './freeRoundBonus/freeRoundsPopupEnd';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import { ReelsContainer } from './reel/reelContainer';
import SafeArea from './safeArea/safeArea';
import { SCENE_CHANGE_FADE_TIME } from './sceneChange/config';
import SceneChange from './sceneChange/sceneChange';
import BigWinsPresentation from './winAnimations/bigWinPresentation';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private static _instance: SlotMachine;

  public static init = (
    app: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine._instance = new SlotMachine(app, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine._instance;

  private application: PIXI.Application;

  private introSoundDelayAnimation: Animation | undefined;

  private windowSize = { width: 0, height: 0 };

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public isStopped = false;

  public isReadyForStop = false;

  public betResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  public gameObjectsContainer: PIXI.Container;

  public menuBtn: MenuButton;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public winCountUpMessage: WinCountUpMessage;

  public miniPayTableContainer: MiniPayTableContainer;

  public reelsContainer: ReelsContainer;

  public slotsContainer: PIXI.Container = new PIXI.Container();

  public fadeArea: FadeArea;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public infoBuyFeatureIcon?: BuyFeatureBtnIcon;

  private constructor(
    app: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    //this.game = Game.getInstance();
    this.application = app;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;

    const startPositions = (
      setUserLastBetResult().id ? setUserLastBetResult().result.reelPositions : slotConfig.settings.startPosition
    ).slice(0, REELS_AMOUNT);

    setPrevReelsPosition(startPositions);
    const reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    setReelSetId(reelSet.id);

    const spinResult = getSpinResult({
      reelPositions: startPositions,
      reelSet,
      icons: slotConfig.icons,
      slotPerReelAmount: BASE_GAME_SLOTS_PER_REEL_AMOUNT,
    });

    this.reelsContainer = new ReelsContainer(getSymbolMatrixFromIcons(spinResult));

    eventManager.emit(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      spinResult.map((icon) => icon.id),
    );

    setLastRegularHistory({
      gameMode: setGameMode(),
      betAmount: setBetAmount(),
      coinAmount: setCoinAmount(),
      balance: setUserBalance().balance,
      winAmount: setWinAmount(),
      reelSetId: reelSet.id,
      reelPositions: startPositions,
      slotIds: spinResult.map((icon) => icon.id),
    });

    // TODO fix later
    //this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, () => {});
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.fadeArea = new FadeArea();
    this.gameView = new GameView({
      slotsDisplayContainer: this.slotsContainer,
      reelsContainer: this.reelsContainer,
      linesContainer: new LinesContainer(slotConfig.winLines),
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
      lines: slotConfig.winLines,
    });
    this.gameView.interactive = true;
    this.gameView.hitArea = new PIXI.Rectangle(
      GAME_VIEW_HIT_AREA.x,
      GAME_VIEW_HIT_AREA.y,
      GAME_VIEW_HIT_AREA.width,
      GAME_VIEW_HIT_AREA.height,
    );

    this.gameView.on('mousedown', () => {
      this.skipAnimations();
      console.log('this.gameView.on');
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.initBuyFeature();
    }

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
      nextTick(() => {
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
    }
    const backGround = new Background();
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);

    const bottomBar = new BottomContainer();

    this.menuBtn = new MenuButton();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();

    const uiContainer = new PIXI.Container();
    uiContainer.addChild(this.menuBtn, this.turboSpinBtn, this.spinBtn, this.betBtn, this.autoplayBtn, this.infoBtn);
    uiContainer.parentLayer = layerUI;

    const bigWinPresentation = new BigWinsPresentation();
    const phoenix = new Phoenix();
    const sceneChange = new SceneChange();

    this.gameObjectsContainer = new PIXI.Container();

    this.gameObjectsContainer.addChild(backGround, safeArea, bottomBar, uiContainer, bigWinPresentation, phoenix);

    this.application.stage.addChild(
      this.gameObjectsContainer,
      new FreeRoundsPopup(),
      new FreeRoundsEndPopup(),
      sceneChange,
    );
    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();

    if (setBrokenGame()) {
      this.onBrokenGame();
    }
    new FreeRoundBonusController();
  }

  private initBuyFeature(): void {
    const buyFeatureBtn = new BuyFeatureBtn();
    const backDrop = new Backdrop();
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();

    //buyFeatureBtn.zIndex = GameViewObjectPriorities['BUY_FEATURE_BUTTON'];
    //backDrop.zIndex = GameViewObjectPriorities['BUY_FEATURE_BACKDROP'];
    //buyFeaturePopup.zIndex = GameViewObjectPriorities['BUY_FEATURE_POPUP'];
    //buyFeaturePopupConfirm.zIndex = GameViewObjectPriorities['BUY_FEATURE_POPUP_CONFIRM'];

    this.gameView.addChild(buyFeatureBtn, backDrop, buyFeaturePopup, buyFeaturePopupConfirm);
  }

  private async onBrokenGame(): Promise<void> {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setCurrentBonusId(setCurrentBonus().id);
    setReelSetId(setCurrentBonus().reelSetId);

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    if (setCurrentFreeSpinsTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      });
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }

    if (
      setUserLastBetResult().reelSetId === reelSets[GameMode.BASE_GAME] ||
      setUserLastBetResult().reelSetId === reelSets[GameMode.BUY_FEATURE]
    ) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: i18n.t('freeSpinsTitle'),
        description: i18n.t('freeSpinsText', {
          spin: setCurrentBonus().rounds,
        }),
        btnText: i18n.t('pressToStart'),
        callback: () => {
          this.setState(SlotMachineState.IDLE);
        },
      });
    } else {
      this.setState(SlotMachineState.IDLE);
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.START_CASCADE_FEATURE, this.startCascadeFeature.bind(this));
    eventManager.addListener(EventTypes.NEXT_CASCADE, this.nextCascade.bind(this));
    eventManager.addListener(EventTypes.AFTER_WIN, this.afterWin.bind(this));
    eventManager.addListener(EventTypes.START_SPIDER_WIN_ANIMATION, () => {
      //this.game.maker.shockWave(
      Game.getInstance().maker.shockWave(
        Game.getInstance().app.stage,
        this.windowSize.width / 2,
        this.windowSize.height / 2,
        {
          amplitude: 70,
          wavelength: 100,
          brightness: 2,
        },
        500,
      );
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId)!;
      const slotPerReelAmount = getSlotPerAmount(settings.mode);
      const spinResult = getSpinResult({
        reelPositions: settings.reelPositions.slice(0, REELS_AMOUNT),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
        slotPerReelAmount,
      });
      this.miniPayTableContainer.setSpinResult(spinResult);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(
        EventTypes.SHOW_STOP_SLOTS_DISPLAY,
        spinResult.map((icon) => icon.id),
      );
      setPrevReelsPosition(settings.reelPositions.slice(0, REELS_AMOUNT));
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }

    if (settings.mode === GameMode.BASE_GAME) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.betResult?.balance.settled);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());

      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinMode(settings.mode)) {
      const bonus = getFreeSpinBonus(this.betResult!);
      if (!bonus) throw new Error('Some went wrong');

      setCurrentBonusId(setCurrentBonus().id);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t('freeSpinsTitle'),
          description: i18n.t('freeSpinsText', {
            spin: this.betResult?.bet.data.bonuses[0]!.rounds,
          }),
          btnText: i18n.t('pressToStart'),
          callback: () => {
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    }
  }

  private startFreeSpins(): void {
    if (setHistoryReplayBet()) return;

    const getBonus = getFreeSpinBonus(this.betResult!);

    setCurrentBonus({
      ...getBonus!,
      isActive: true,
      currentRound: 0,
    });
    setCurrentFreeSpinsTotalWin(this.betResult!.bet.result.winCoinAmount);
    setIsFreeSpinsWin(true);

    const animationChain = new AnimationChain();
    {
      const sceneChange = Tween.createDelayAnimation(1000);
      sceneChange.addOnComplete(() => {
        eventManager.emit(EventTypes.SCENE_CHANGE_DOWN, () => {
          eventManager.emit(EventTypes.CHANGE_MODE, {
            mode: GameMode.FREE_SPINS,
            reelPositions: Array<number>(REELS_AMOUNT).fill(0),
            reelSetId: reelSets[GameMode.FREE_SPINS]!,
          });
          eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, 0, true);
        });
      });
      animationChain.appendAnimation(sceneChange);
    }
    animationChain.start();
  }

  private async endFreeSpins(): Promise<void> {
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: setCurrentBonus().betId } },
      fetchPolicy: 'network-only',
    });

    const { reelSetId, reelPositions } = {
      reelSetId: bet.data.bet.reelSetId,
      reelPositions: bet.data.bet.result.reelPositions,
    };

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    const callback = () => {
      eventManager.emit(EventTypes.SCENE_CHANGE_UP, () => {
        eventManager.emit(EventTypes.CHANGE_MODE, {
          mode: GameMode.BASE_GAME,
          reelPositions,
          reelSetId,
        });
      });

      setTimeout(() => {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }, 100);

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );
      }, SCENE_CHANGE_FADE_TIME);
    };

    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    const props = {
      totalWin: `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
      preventDefaultDestroy: true,
      title: i18n.t('youWon'),
    };

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, { ...props, callback });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, { ...props, onInitCallback: () => delay.start() });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[REELS_AMOUNT - 1]!.cascadeAnimation?.getWaiting().removeOnComplete(
      this.throwTimeoutError,
    );
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, current.toString(), total.toString(), false);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.betResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange(this.betResult!.bet.reelSet.id);
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            getSymbolMatrixFromIcons(this.betResult.bet.result.spinResult),
            this.getScatterCount(this.betResult.bet.result.spinResult),
            this.betResult.bet.data.features.initMultiplierMatrix,
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);

      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.betResult = null;

      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!!isTurboSpin);

      if (isFreeSpinMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, bonus.currentRound, false);
        setCurrentBonus(bonus);
      }

      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(_isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    this.reelsContainer.reels.forEach((reel, reelId) => {
      const spinAnimation = reel.createSpinAnimation();
      if (reelId === REELS_AMOUNT - 1) {
        spinAnimation.getWaiting().addOnChange(() => {
          if (this.betResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getSymbolMatrixFromIcons(this.betResult.bet.result.spinResult),
              this.getScatterCount(this.betResult.bet.result.spinResult),
              this.betResult.bet.data.features.initMultiplierMatrix,
            );
          }
        });
        spinAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
      }
      animationGroup.addAnimation(spinAnimation);
    });

    this.reelsContainer.multiplierSymbolReels.forEach((reel) => {
      const spinAnimation = reel.createSpinAnimation();
      animationGroup.addAnimation(spinAnimation);
    });

    return animationGroup;
  }

  private updateWinAmount(winAmount = 0) {
    if (isFreeSpinMode(setGameMode())) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmount);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    } else {
      setWinAmount(winAmount);
    }
    eventManager.emit(EventTypes.ADD_WIN_AMOUNT, winAmount);
  }

  private onCountUpEnd(): void {
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    const winAmount = this.betResult!.bet.result.winCoinAmount;
    this.updateWinAmount(winAmount);

    eventManager.emit(EventTypes.COUNT_UP_END);

    const gameMode = setGameMode();
    if (!isFreeSpinMode(gameMode) && getFreeSpinBonus(this.betResult!)) {
      this.startFreeSpins();
    }
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.betResult?.balance.settled);
    this.setState(SlotMachineState.IDLE);
  }

  private dynamicReelSetChange(reelId: string): void {
    if (setReelSetId() !== reelId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelId)!,
        reelPositions: [0, 0, 0, 0, 0, 0, 0, 0],
      });
      setReelSetId(reelId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.onSpinStop(isTurboSpin);
  }

  private getScatterCount(spinResult: Icon[]): number[] {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.SC)) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (!setIsDuringWinCountUpAnimation()) {
      if (this.state === SlotMachineState.IDLE || this.state === SlotMachineState.WINNING) {
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      }
    }
  }

  public setPlaceBetResult(betResult: ISettledBet): void {
    const reelSet = setSlotConfig().reels.find((reelSet) => reelSet.id === betResult.bet.reelSet.id)!;
    const spinResult = getSpinResult({
      reelPositions: betResult.bet.result.reelPositions.slice(0, REELS_AMOUNT),
      reelSet,
      icons: setSlotConfig().icons,
      slotPerReelAmount: getSlotPerAmount(getGameModeByReelSetId(reelSet.id)),
    });

    betResult.bet.result.spinResult = spinResult;
    setPrevReelsPosition(betResult.bet.result.reelPositions.slice(0, REELS_AMOUNT));

    this.betResult = betResult;
    setBetResult(betResult);

    const cascades = getCascades(this.betResult!.bet.data.features.gameRoundStore.cascadeData!);
    setCascades(cascades);

    const winData = getCascadeWins(this.betResult?.bet.data.features.gameRoundStore.cascadeData!);
    setCascadeWins(winData);

    const gameMode = setGameMode();
    if (isRegularMode(gameMode) || isBuyFeatureMode(gameMode)) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.betResult.balance.placed);
      setUserBalance({ ...setUserBalance(), balance: betResult.balance.placed });

      const isStartPhoenix = lotteryPhoenix(setCascades());
      if (isStartPhoenix) {
        eventManager.emit(EventTypes.PHOENIX_START);
      }

      const slotIds = setCascades().length
        ? setCascades()[setCascades().length - 1]!.slotIds
        : spinResult.map((icon) => icon.id);
      if (setHistoryReplayBet() === null) {
        setLastRegularHistory({
          gameMode,
          betAmount: setBetAmount(),
          coinAmount: setCoinAmount(),
          balance: setUserBalance().balance,
          winAmount: betResult.bet.result.winCoinAmount,
          reelSetId: this.betResult!.bet.reelSet.id,
          reelPositions: this.betResult!.bet.result.reelPositions,
          slotIds,
        });
      }
    } else if (isFreeSpinMode(gameMode)) {
      this.betResult.bet.data.features.initMultiplierMatrix = getMultiplierMatrix(
        this.betResult.bet.data.features.gameRoundStore.cascadeData.multiplierHistory[0]!.flat(),
      );
    }

    console.info(this.betResult);
    console.info(this.betResult?.bet.data.features.gameRoundStore.cascadeData!);
    console.log(
      this.betResult?.bet.data.features.gameRoundStore.cascadeData.spinMatrixesChanges.forEach((cascade, i) => {
        const symbols = cascade.reduce<SlotId[]>((acc, symbol) => {
          acc.push(symbol);
          return acc;
        }, []);
        this.debugSymbolCountsInMatrix(i, getSymbolMatrixFromSymbols(symbols));
      }, []),
    );
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    /* TODO fix later
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult); */
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  /* TODO fix later
  public getSlotAt(x: number, y: number): MoleSlot | null {
    return this.moleSlotsContainer.slots[y * REELS_AMOUNT + x]!;
  } */

  /* TODO fix later
  public getSlotById(id: number): MoleSlot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  } */

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.windowSize = { width, height };
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.betResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
        setIsFreeSpinsWin() ||
        setIsContinueAutoSpinsAfterFeature() ||
        setIsInTransition(),
    );

    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinMode(setGameMode())) {
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          const endDelay = Tween.createDelayAnimation(1000);
          endDelay.addOnComplete(() => this.endFreeSpins());
          endDelay.start();
        } else if (setCurrentBonus().isActive) {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      } else if (setIsContinueAutoSpinsAfterFeature()) {
        this.skipAnimations();
        setTimeout(() => eventManager.emit(EventTypes.SPACE_KEY_SPIN), 500);
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }

    if (state === SlotMachineState.JINGLE) {
      this.setState(SlotMachineState.WINNING);
    }

    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(EventTypes.START_CASCADE_FEATURE);
      } else {
        this.onCountUpEnd();
      }
    }
  }

  private debugSymbolCountsInMatrix(cascadeStep: number, matrix: SlotId[][]) {
    console.log(`fe:[${cascadeStep}]->`);
    console.log(matrix);
    Object.values(SlotId).forEach((slotId) => {
      const symbols = matrix.flatMap((v) => v);
      console.log(`[${slotId}]: ${symbols.filter((s) => s === slotId).length}`);
    });
  }

  private startCascadeFeature(): void {
    const cascades = setCascades();
    console.info(cascades);
    eventManager.emit(EventTypes.START_WIN_ANIMATION, setCascadeWins()[0]!, 0);
  }

  private nextCascade(cascadeStep: number): void {
    const cascadeWins = setCascadeWins();
    const cascades = setCascades();
    const cascadedMatrix = getCascadedSymbolMatrix(
      this.betResult!.bet.result.spinResult.map((icon) => icon.id),
      cascades,
      cascadeStep,
    );

    const afterWinMatrix = getSymbolMatrixFromSymbols(cascadeWins[cascadeStep - 1]!.afterWinningSymbols);
    console.log(afterWinMatrix);
    const cascade = cascades[cascadeStep - 1]!;

    const animationChain = new AnimationChain();
    const cascadeAnimation = new AnimationGroup();
    this.reelsContainer.reels.forEach((reel, reelId) => {
      if (afterWinMatrix[reelId]!.includes('')) {
        const remainAnimation = reel.createRemainSymbolCascadeAnimation(afterWinMatrix[reelId]!);
        const fallSymbols = cascade.cascadeFall[reelId]!.filter((v): v is Exclude<typeof v, ''> => v !== '');
        const winPositionIndexes = afterWinMatrix[reelId]!.flatMap((v, i) => (v === '' ? i : []));
        const newFallAnimation = reel.createNewSymbolCascadeAnimation(winPositionIndexes, fallSymbols!, [
          ...cascadedMatrix[reelId]!,
        ]);
        cascadeAnimation.addAnimation(remainAnimation);
        cascadeAnimation.addAnimation(newFallAnimation);

        // multiplier
        if (
          cascadeWins[cascadeStep - 1]!.afterWinningMultipliers! &&
          cascadeWins[cascadeStep - 1]!.afterWinningMultipliers!.length > 0
        ) {
          const multiplierReel = this.reelsContainer.multiplierSymbolReels[reelId]!;
          const multipliers = cascadeWins[cascadeStep - 1]!.afterWinningMultipliers!;

          const multiplierRemainAnimation = multiplierReel.createRemainSymbolCascadeAnimation(
            afterWinMatrix[reelId]!,
            multipliers[reelId]!,
          );
          cascadeAnimation.addAnimation(multiplierRemainAnimation);
          const fallMultipliers =
            cascade.multiplierMatrix && cascade.multiplierMatrix[reelId]!.slice(0, fallSymbols.length);

          const newFallAnimation = multiplierReel.createNewSymbolCascadeAnimation(winPositionIndexes, fallMultipliers!);
          cascadeAnimation.addAnimation(newFallAnimation);
        }
      }
      reel.initIdleSlots([...cascadedMatrix[reelId]!]);

      cascadeAnimation.addOnComplete(() => {
        reel.idleSlots();
        if (
          cascadeWins[cascadeStep - 1]!.afterWinningMultipliers! &&
          cascadeWins[cascadeStep - 1]!.afterWinningMultipliers!.length > 0
        ) {
          const multiplierReel = this.reelsContainer.multiplierSymbolReels[reelId]!;
          if (cascade.multiplierMatrix && multiplierReel) {
            multiplierReel.reset(cascade.multiplierMatrix[reelId]!);
          }
        }
      });
    });
    animationChain.appendAnimation(cascadeAnimation);
    animationChain.appendAnimation(Tween.createDelayAnimation(30));

    // hide
    const idleAnimationGroup = new AnimationGroup();
    this.reelsContainer.reels.forEach((reel, reelId) => {
      if (afterWinMatrix[reelId]!.includes('')) {
        const animation = Tween.createDelayAnimation(0);
        animation.addOnStart(() => {
          reel.initAnimationSlots([...cascadedMatrix[reelId]!]);
          reel.hideAnimationSlots();
        });
        idleAnimationGroup.addAnimation(animation);
      }
    });
    animationChain.appendAnimation(idleAnimationGroup);
    animationChain.appendAnimation(Tween.createDelayAnimation(300));

    // stop
    const stopAnimationGroup = new AnimationGroup();
    this.reelsContainer.reels.forEach((reel, _reelId) => {
      const animation = Tween.createDelayAnimation(0);
      animation.addOnStart(() => {
        reel.startStopAnimation();
      });
      stopAnimationGroup.addAnimation(animation);
    });

    animationChain.appendAnimation(stopAnimationGroup);
    animationChain.appendAnimation(Tween.createDelayAnimation(30));

    animationChain.addOnComplete(() => {
      if (cascadeStep < cascades.length) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, setCascadeWins()[cascadeStep]!, cascadeStep);
      } else {
        if (cascadeWins.length > cascades.length) {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, setCascadeWins()[cascadeStep]!, cascadeStep);
        } else {
          this.afterWin();
        }
      }
    });

    console.log('slotMachine->nextCascade->start');
    animationChain.start();
  }

  private afterWin() {
    const winAmount = this.betResult!.bet.result.winCoinAmount;
    if (getWinStage(winAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        this.onCountUpEnd();
      });
      eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winAmount);
    } else {
      this.onCountUpEnd();
    }
  }
}

export default SlotMachine;

import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode, MessageFreeSpinsBannerProps, MessageWinBannerProps } from '../../global.d';
import { setBetAmount, setCurrentFreeSpinsTotalWin, setIsOpenedMessageBanner } from '../../gql';
import i18n from '../../i18next';
import { isFreeSpinMode, normalizeCoins, updateCoinValueAfterBonuses } from '../../utils';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import { ViewContainer } from '../components/viewContainer';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../config';
import { IGameContainer } from '../d';
import { FreeSpinsCounter } from '../freeSpinsCounter/freeSpinsCounter';
import { BaseMessageBanner } from '../messageBanner/baseMessageBanner';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import GameReplay from '../replay/gameReplay';

import GameTitleLogo from './gameTitleLogo';
import { ResponseWaitingLogo } from './responseWaitingLogo';

class GameView extends ViewContainer {
  public linesContainer: Container;

  public reelsContainer: Container;

  public slotsContainer: Container;

  public slotsDisplayContainer: Container;

  public gameTitleLogo: GameTitleLogo;

  public miniPayTableContainer: Container;

  public winLabelContainer: Container;

  public winCountUpMessage: Container;

  private messageBanner: BaseMessageBanner | null = null;

  private windowSize = { width: 0, height: 0 };

  private responseWaitingLogo: ResponseWaitingLogo;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;

    this.slotsContainer = new Container();
    this.slotsContainer.scale.set(1, 1);

    this.reelsContainer = props.reelsContainer;
    this.slotsDisplayContainer = props.slotsDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.linesContainer = props.linesContainer;
    this.winLabelContainer = props.winLabelContainer;
    this.winCountUpMessage = props.winCountUpMessage;

    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.slotsDisplayContainer);

    this.responseWaitingLogo = new ResponseWaitingLogo();

    this.gameTitleLogo = new GameTitleLogo();

    this.addChild(this.gameTitleLogo);
    this.addChild(this.slotsContainer);
    this.addChild(this.responseWaitingLogo);
    this.addChild(new FreeSpinsCounter());
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(new GameReplay());

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessageBanner.bind(this));
  }

  private createFreeSpinsMessage(props: MessageFreeSpinsBannerProps): void {
    const messageBanner = new MessageFreeSpinsBanner(props);
    messageBanner.init();
    this.messageBanner = messageBanner;
    this.scaleBanner();
    this.addChild(messageBanner);
  }

  private createWinMessageBanner(props: MessageWinBannerProps): void {
    const bet = normalizeCoins(setBetAmount());
    const totalWin = setCurrentFreeSpinsTotalWin();
    const trueEnd = totalWin >= bet * 200;
    const soundKey = trueEnd ? ISongs.SONG_032_05_TotalWinBanner_B : ISongs.SONG_032_04_TotalWinBanner_A;
    AudioApi.play({ type: soundKey, stopPrev: true });

    const soundTiming = Tween.createDelayAnimation(mappedAudioSprites[soundKey].duration);
    soundTiming.addOnStart(() => {
      BgmControl.fadeOutAll(0);
    });
    soundTiming.addOnSkip(() => {
      AudioApi.fadeOut(1000, soundKey);
    });

    soundTiming.start();

    const messageWinBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        soundTiming.skip();
        if (props.callback) props.callback();
      },
      title: trueEnd ? i18n.t('youWonTrueEnd') : props.title,
    }).init(trueEnd);

    this.messageBanner = messageWinBanner;
    this.scaleBanner();

    this.addChild(messageWinBanner);
  }

  override resize(width: number, height: number) {
    this.windowSize = { width, height };
    this.scaleBanner();
  }

  private scaleBanner() {
    const isLandScape = this.windowSize.width > this.windowSize.height;
    if (this.messageBanner !== null && setIsOpenedMessageBanner()) {
      this.messageBanner.scale.set(isLandScape ? 1 : 0.95);
    }
  }

  private resizeGameContainer(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }

  override onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinMode(settings.mode)) {
      //
    } else {
      updateCoinValueAfterBonuses();
    }
  }
}

export default GameView;

import { GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = -58;

export const MESSAGE_FREE_SPINS_BANNER_Y = 0;
export const MESSAGE_FREE_SPINS_BANNER_TITLE_Y = 210;
export const MESSAGE_FREE_SPINS_BANNER_DESCRIPTION_Y = 330;
export const MESSAGE_FREE_SPINS_BANNER_DESCRIPTIONS1_Y = 550;
export const MESSAGE_FREE_SPINS_BANNER_BUTTON_Y = 740;

export const MESSAGE_WIN_BANNER_Y = MESSAGE_FREE_SPINS_BANNER_Y;
export const MESSAGE_WIN_BANNER_TITLE_Y = 220;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_X = 0;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 380;

export const MESSAGE_TRUE_END_WIN_BANNER_Y = MESSAGE_FREE_SPINS_BANNER_Y;
export const MESSAGE_TRUE_END_WIN_BANNER_TITLE_Y = 300;
export const MESSAGE_TRUE_END_WIN_BANNER_WIN_AMOUNT_Y = 480;

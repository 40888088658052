import { Container } from 'pixi.js';

import { Charset } from './charSet';
import { RainCharsColumn } from './rainCharsColumn';

export interface IMatrixRainAnimationOptions {
  cellSize: number;
  chanceToRevertChar: number;
  coolDownRange: [number, number];
  fadeRate: number;
  growRate: number;
  growRateRange: [number, number];
  highlight: number;
  maxPlayCount: number;
  maxLength: number;
  minPlayCount: number;
  playFramesTimeout: number;
  tint: number;
}

export const defaultOptions: IMatrixRainAnimationOptions = {
  cellSize: 12,
  chanceToRevertChar: 0.1,
  coolDownRange: [100, 300],
  fadeRate: 50,
  growRate: 150,
  growRateRange: [100, 300],
  highlight: 0xe2fe70,
  playFramesTimeout: 120,
  minPlayCount: 1,
  maxLength: 0,
  maxPlayCount: 10,
  tint: 0x15b519,
};

export const customOptions: IMatrixRainAnimationOptions = {
  maxLength: 0,
  fadeRate: 50,
  chanceToRevertChar: 0.1,
  playFramesTimeout: 120,
  minPlayCount: 1,
  maxPlayCount: 10,
  cellSize: 12,
  coolDownRange: [100, 1000],
  growRateRange: [100, 300],
  growRate: 150,
  highlight: 0xe2fe70,
  tint: 0x15b519,
};

export const getRandomFromRange = (range = [0, 1]) => range[0]! + Math.random() * (range[1]! - range[0]!);

export class MatrixRainAnimation {
  public options: IMatrixRainAnimationOptions;
  public readonly container: Container;
  public readonly parentContainer: Container;
  private rainCharsColumns: RainCharsColumn[] = [];

  constructor(parentContainer: Container, options?: Partial<IMatrixRainAnimationOptions>) {
    this.container = new Container();
    this.options = { ...customOptions, ...options };
    this.parentContainer = parentContainer;
  }

  public async start(width: number, height: number): Promise<void> {
    const cellSize = this.options.cellSize;
    const columns = Math.floor(width / cellSize);
    const textures = Charset.getTextures({ fontSize: cellSize });
    for (let i = 0; i < columns; i++) {
      const rainCharsColumn = new RainCharsColumn(i * cellSize, textures, {
        ...this.options,
        growRate: getRandomFromRange(this.options.growRateRange),
        maxLength: this.options.maxLength === 0 ? Math.ceil(height / cellSize) : this.options.maxLength,
      });
      //rainCharsColumn.zIndex = 1;
      this.container.addChild(rainCharsColumn);
      this.rainCharsColumns.push(rainCharsColumn);
    }
    this.parentContainer.addChild(this.container);
  }

  public clear() {
    this.rainCharsColumns.forEach((e) => e.clear());
    this.parentContainer.removeChild(this.container);
    this.container.destroy();
  }
}

import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

const GRADIENT_GOLD_AMOUNT_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#fd4766', '#fd47cc', '#fd47f2', '#fd47cc', '#fd4766'],
  fillGradientType: 0,
  stroke: '#ffffff',
  dropShadow: true,
  dropShadowColor: 0xff12f1,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 4,
  miterLimit: 1,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#47fd53aa', '#47fd53cc', '#47fd53', '#47fd53cc', '#47fd53aa'],
  stroke: '#11111166',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  miterLimit: 1,
};
export const titleTextStyle = new TextStyle({
  fontSize: 100,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
  lineJoin: 'round',
});
export const descriptionsTextStyle = {
  fontSize: 40,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  stroke: '#000000',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1,
  dropShadowDistance: 4,
  miterLimit: 4,
  lineJoin: 'round',
};
export const btnTextStyle = new TextStyle({
  fontSize: 60,
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#47a5fdaa', '#47a5fdcc', '#47a5fdff', '#47a5fdcc', '#47a5fdaa'],
  stroke: '#47a5fdff33',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1.0,
  dropShadowDistance: 4,
  miterLimit: 4,
  lineJoin: 'round',
});
export const totalWinTitleStyles = {
  ...titleTextStyle,
  fontSize: 120,
};
export const totalWinAmountTextStyles = new TextStyle({
  fontSize: 160,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 10,
  lineJoin: 'round',
});

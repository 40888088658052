import { Colors } from '../../../config';

export const CAROUSEL_IMAGE_HEIGHT = 700;
export const CAROUSEL_DOTS_DEFAULT_COLOR = '#a29d9e';
export const CAROUSEL_DOTS_ACTIVE_COLOR = '#ffffff';
export const CAROUSEL_DOTS_BLUR_SIZE = 5;
export const CAROUSEL_DOTS_SIZE = 36;
export const CAROUSEL_DOTS_GAP = 18;
export const CAROUSEL_TEXT_SIZE = 37;
export const CAROUSEL_TEXT_MOBILE_SIZE = 32;
export const CAROUSEL_TEXT_LINE_HEIGHT = 25;
export const CAROUSEL_LETTER_SIZE = 30;
export const CAROUSEL_TEXT_MOBILE_LETTER = 38;
export const CAROUSEL_TEXT_COLOR = 0xffffff;
export const CAROUSEL_TEXT_STROKE_COLOR = 0x000000;
export const CAROUSEL_TEXT_SHADOW = 0x000000;
export const CAROUSEL_TEXT_DIVIDE_COLOR = Colors.GAME_COLOR;
export const CAROUSEL_TEXT_DIVIDE_SECOND_COLOR = 0x3be6ff;
export const CAROUSEL_TEXT_DIVIDE_THIRD_COLOR = 0xea00ff;
export const CAROUSEL_TEXT_WORD_WRAP_WIDTH = undefined;
export const CAROUSEL_ANIMATION_DELAY = 4000;
export const CAROUSEL_ANIMATION_DURATION = 500;

export const DEFAULT_CAROUSEL_TEXT = {
  fill: CAROUSEL_TEXT_COLOR,
  fontSize: CAROUSEL_TEXT_SIZE,
  lineHeight: CAROUSEL_TEXT_LINE_HEIGHT,
  wordWrap: true,
  stroke: CAROUSEL_TEXT_STROKE_COLOR,
  strokeThickness: 9,
  fontWeight: '700',
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 20,
  dropShadowColor: CAROUSEL_TEXT_SHADOW,
  dropShadowDistance: 0,
};

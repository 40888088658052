import { SpineInterface } from '../../config/spine.generated';
import { Game } from '../../game';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql';
import { isRegularMode } from '../../utils';
import Animation from '../animations/animation';
import { ZOrderReelContainer } from '../components/layer/config';
import { layerReel } from '../components/layer/layer';
import { StrictSpine } from '../components/spine';
import { SlotMachineState, eventManager } from '../config';

type ReelAnimationNames = SpineInterface['reelframe']['animations'];

export class ReelFrame {
  public spine: StrictSpine<'reelframe'>;

  constructor() {
    this.spine = Game.getInstance().maker.spine('reelframe');

    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
      if (state === SlotMachineState.IDLE) {
        this.startIdleAnimation(setGameMode());
      }
    });
    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.startIdleAnimation(settings.mode, true);
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.startIdleAnimation(settings.mode, true);
    });
    eventManager.addListener(EventTypes.COUNT_UP_END, () => {
      this.startSpinEndAnimation(setGameMode());
    });

    this.startIdleAnimation(GameMode.BASE_GAME);

    this.spine.parentLayer = layerReel;
    this.spine.zOrder = ZOrderReelContainer.REEL_FRAME;
  }

  private startSpinEndAnimation(mode: GameMode) {
    const animationName = isRegularMode(mode) ? 'bg_spinend' : 'fg_spinend';
    this.spine.state.setAnimation(0, animationName, true);
  }

  public startIdleAnimation(mode: GameMode, forceIdle = false) {
    const animationName = isRegularMode(mode) ? 'bg_idle' : 'fg_idle';
    const trackEntry = this.spine.state.getCurrent(0);

    if (forceIdle || !trackEntry) {
      this.spine.state.setAnimation(0, animationName, true);
      return;
    }
    if (trackEntry.animation?.name !== animationName) {
      if (
        trackEntry.animation &&
        (trackEntry.animation?.name === 'bg_spinend' || trackEntry.animation?.name === 'fg_spinend')
      ) {
        this.spine.state.addAnimation(0, animationName, true);
      } else {
        this.spine.state.setAnimation(0, animationName, true);
      }
    }
  }

  public startWinAnimation(mode: GameMode) {
    const animationName = isRegularMode(mode) ? 'bg_win' : 'fg_win';
    const addAnimationName = isRegularMode(mode) ? 'bg_idle' : 'fg_idle';
    this.spine.state.setAnimation(0, animationName, false);
    this.spine.state.addAnimation(0, addAnimationName, true);
  }

  public getSpineAnimation(mode: GameMode, animationAbbv: 'idle' | 'win'): Animation {
    const modeAbbv = isRegularMode(mode) ? 'bg' : 'fg';
    const animationName = `${modeAbbv}_${animationAbbv}` as ReelAnimationNames;
    return this.spine.getAnimation(0, animationName);
  }
}

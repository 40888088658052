import { BaseMessageBannerProps, EventTypes } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql/cache';
import { layerTransition } from '../components/layer/layer';
import { ViewContainer } from '../components/viewContainer';
import { eventManager } from '../config';

export class BaseMessageBanner extends ViewContainer {
  protected props: BaseMessageBannerProps;

  protected bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: BaseMessageBannerProps) {
    super();

    this.visible = true;
    this.interactive = true;

    setIsOpenedMessageBanner(true);

    this.props = props;
    this.parentLayer = layerTransition;
  }

  protected init(): BaseMessageBanner {
    this.on('click', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER, () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);

    if (this.props.onInitCallback) {
      this.props.onInitCallback();
    }
    return this;
  }

  public handleDestroy(): void {
    setIsOpenedMessageBanner(false);
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeListener(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER, this.bindedHandleDestroy);

    this.destroy({ children: true });
  }
}

import { Container } from 'pixi.js';

import { CascadeFallPossibleSymbol } from '../../../global';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import Tween from '../../animations/tween';
import { ZOrderReelContainer } from '../../components/layer/config';
import { layerReel } from '../../components/layer/layer';
import { REELS_AMOUNT } from '../../config';
import { CascadeAnimation } from '../spin';

import { MultiplierSymbol } from './multiplierSymbol';

const freeSpinsOuterToInnerStepIndexes = [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3], [0, 1, 2, 3, 4]];

export class MultiplierSymbolReel {
  private id: number;

  public multiPlierSymbols: MultiplierSymbol[] = [];

  public cascadeAnimation?: CascadeAnimation;

  public container = new Container();

  constructor(id: number, multipliers?: number[]) {
    this.id = id;
    this.container.angle = (360 / REELS_AMOUNT) * id;

    if (multipliers) {
      this.init(multipliers);
    }

    this.container.parentLayer = layerReel;
    this.container.zOrder = ZOrderReelContainer.MULTIPLIER_REEL;
  }

  public clear() {
    this.container.removeChildren();
    this.multiPlierSymbols = [];
  }

  private init(multipliers: number[]) {
    this.clear();
    multipliers.forEach((v, i) => {
      const mpSymbol = new MultiplierSymbol(v, this.id, i);
      this.container.addChild(mpSymbol);
      this.multiPlierSymbols.push(mpSymbol);
    });
  }

  public reset(multipliers: number[]) {
    this.multiPlierSymbols.forEach((v, i) => {
      v.changePosition(i);
      v.changeMultiplierValue(multipliers[i]!, i);
      v.visible = multipliers[i]! > 0;
      v.getSpineAnimation(i, 'stop').start();
    });
  }

  public createSpinAnimation() {
    const disappearingAnimation = new AnimationChain({ isLoop: false });

    this.multiPlierSymbols.forEach((_, i) => {
      const animationGroup = new AnimationGroup();
      const cascadeSymbols = this.multiPlierSymbols.slice(0, this.multiPlierSymbols.length - i);
      const disappearSymbol = this.multiPlierSymbols[this.multiPlierSymbols.length - i];
      animationGroup.addOnStart(() => {
        if (disappearSymbol) {
          disappearSymbol!.visible = false;
        }
      });
      cascadeSymbols.forEach((symbol, j) => {
        const index = i + j;
        const animation = symbol.getSpineAnimation(index, 'stop');
        animation.addOnStart(() => {
          symbol.changePosition(index);
        });
        animationGroup.addAnimation(animation);
      });
      disappearingAnimation.appendAnimation(animationGroup);
      disappearingAnimation.appendAnimation(Tween.createDelayAnimation(33));
    });

    disappearingAnimation.addOnComplete(() => {
      this.multiPlierSymbols.forEach((v) => (v.visible = false));
    });

    this.cascadeAnimation = new CascadeAnimation({
      disappearingAnimation: disappearingAnimation,
      waitingAnimation: Tween.createDelayAnimation(15 * 1000),
    });

    return this.cascadeAnimation;
  }

  private createAppearingAnimation() {
    const appearingAnimation = new AnimationChain();

    // wait between reels
    appearingAnimation.appendAnimation(Tween.createDelayAnimation(this.id * 33));

    const cascadeStepSymbols: MultiplierSymbol[][] = [];
    this.multiPlierSymbols.forEach((_, i) => {
      cascadeStepSymbols.push(this.multiPlierSymbols.slice(0, i + 1));
    });

    cascadeStepSymbols.forEach((slots, i) => {
      const animationGroup = new AnimationGroup();
      slots.forEach((symbol, j) => {
        const index = freeSpinsOuterToInnerStepIndexes[i]![j]!;
        const animation = symbol.getSpineAnimation(index, 'stop');
        animation.addOnStart(() => {
          if (symbol.multiplier > 0) {
            symbol.changePosition(index);
            symbol.visible = true;
          } else {
            symbol.visible = false;
          }
        });
        animationGroup.addAnimation(animation);
      });
      appearingAnimation.appendAnimation(animationGroup);
      appearingAnimation.appendAnimation(Tween.createDelayAnimation(1));
    });

    return appearingAnimation;
  }

  public startSpinStopAnimation(multipliers: number[]) {
    this.init(multipliers);
    this.multiPlierSymbols.forEach((v) => (v.visible = false));

    const appearingAnimation = this.createAppearingAnimation();
    this.cascadeAnimation?.appendAnimation(appearingAnimation);

    const waitingAnimation = this.cascadeAnimation!.getWaiting();
    if (waitingAnimation.ended) {
      waitingAnimation.duration = 1;
      waitingAnimation.start();
    }

    waitingAnimation.end();
  }

  public createWinAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.multiPlierSymbols.forEach((symbol, index) => {
      if (winSlotIndexes.includes(index)) {
        if (symbol.multiplier > 0) {
          const win = symbol.getSpineAnimation(index, 'win');
          animationGroup.addAnimation(win);
        }
      }
    });

    return animationGroup;
  }

  public createLostAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.multiPlierSymbols.forEach((symbol, index) => {
      if (winSlotIndexes.includes(index)) {
        if (symbol.multiplier > 0) {
          const lost = symbol.getSpineAnimation(index, 'lost');
          lost.addOnStart(() => {
            Tween.setTimeout(() => {
              symbol.multiplier = 0;
              symbol.visible = false;
            }, 500);
          });
          animationGroup.addAnimation(lost);
        }
      }
    });

    return animationGroup;
  }

  public createRemainSymbolCascadeAnimation(remainingSymbols: CascadeFallPossibleSymbol[], multipliers: number[]) {
    const animation = new AnimationChain();

    for (let i = 0; i < remainingSymbols.length - 1; i++) {
      const id = remainingSymbols[i];
      if (id !== '') {
        const animationIndexes = remainingSymbols.reduce<number[]>((acc, v, j) => {
          if (j > i && v === '') {
            acc.push(i + 1 + acc.length);
            return acc;
          }
          return acc;
        }, []);
        const mpSymbol = this.multiPlierSymbols[i]!;
        if (multipliers[i]! > 0) {
          if (animationIndexes.length > 0) {
            animationIndexes.forEach((index) => {
              const stopAnimation = mpSymbol.getSpineAnimation(index, 'stop');
              stopAnimation.addOnStart(() => {
                mpSymbol.changePosition(index);
              });
              animation.appendAnimation(stopAnimation);
              animation.appendAnimation(Tween.createDelayAnimation(30));
            });
            animation.appendAnimation(Tween.createDelayAnimation(60));
          }
        } else {
          mpSymbol.visible = false;
        }
      }
    }
    return animation;
  }

  public createNewSymbolCascadeAnimation(remainIndexes: number[], fallMultipliers: number[]) {
    const animationChain = new AnimationChain();
    const reverse = [...fallMultipliers].reverse();
    const reverseIndexes = [...fallMultipliers].flatMap((_, i) => i).reverse();
    reverse.forEach((v, j) => {
      if (v > 0) {
        const i = reverseIndexes[j]!;
        const animationIndexes = freeSpinsOuterToInnerStepIndexes[i]!;
        const multiplierSymbol = this.multiPlierSymbols[remainIndexes[i]!]!;
        animationIndexes.forEach((index) => {
          const stopAnimation = multiplierSymbol.getSpineAnimation(index, 'stop');
          stopAnimation.addOnStart(() => {
            multiplierSymbol.changeMultiplierValue(v, index);
            multiplierSymbol.changePosition(index);
            multiplierSymbol.visible = v > 0;
          });
          animationChain.appendAnimation(stopAnimation);
          animationChain.appendAnimation(Tween.createDelayAnimation(30));
        });
        animationChain.appendAnimation(Tween.createDelayAnimation(60));
      }
    });
    return animationChain;
  }
}

import * as PIXI from 'pixi.js';

import { Colors, Variables } from '../../config';

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#ff4f4f', '#d64fff', '#4fd0ff', '#78ff4f'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 200,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 4,
  padding: 4,
  stroke: '#7bff8488',
  strokeThickness: 9,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});
const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowColor: 0xff12f1,
  dropShadowDistance: 4,
  fill: ['#fd4766', '#fd47cc', '#fd47f2ff', '#fd47cc', '#fd4766'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 160,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 4,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 10,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});
export const bigWinValueStyles = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowColor: 0xff12f1,
  dropShadowDistance: 4,
  fill: ['#fd4766', '#fd47cc', '#fd47f2ff', '#fd47cc', '#fd4766'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 192,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 4,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 12,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});
export const miniPayTableNumberStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fill: '0xffffff',
  trim: true,
};
export const miniPayTableTextStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fill: '0xf6e87e',
  trim: true,
};
export const bottomContainerTextStyle = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};
// BuyFeature Button Text
export const FEATURE_BTN_TEXT_LANDSCAPE_FIT_TO_WIDTH = 120;
export const FEATURE_BTN_TEXT_PORTRAIT_FIT_TO_WIDTH = 250;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 48,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#47fd53'],
  stroke: '#47fd5344',
  strokeThickness: 4,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 52,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#47fd53'],
  stroke: '#dddddd',
  strokeThickness: 4,
});
export const buyFeatureClickTextStyle = buyFeatureTextStyle;
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 46,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#101010', '#383838', '#727272', '#383838', '#101010'],
  stroke: '#000000',
  strokeThickness: 2,
});
export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winCounterStyle);
export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
export const autoPlayCounterTextStyle = new PIXI.TextStyle({
  fontWeight: 'bold',
  fontFamily: Variables.FONT_FAMILY,
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
});
export const clockTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});
export const gameNameTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 16,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});
export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};
export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};
export const miniPayTableAdditionalTextStyle = new PIXI.TextStyle({
  fontSize: 28,
  lineHeight: 30,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
  wordWrap: true,
  wordWrapWidth: 220,
  breakWords: true,
  align: 'center',
});

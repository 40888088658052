import { Container, Graphics, Sprite, Texture } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setFreeRoundsTotalWin, setIsPopupFreeRoundsOpened } from '../../gql/cache';
import i18n from '../../i18next';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/viewContainer';
import { eventManager } from '../config';

import { LAYOUT_OPTIONS, buttonTextStyle, freeRoundsTextStyles } from './config';
import { updateCoinValueFromFreeRoundBonus } from './helper';

export class FreeRoundsPopup extends ViewContainer {
  protected popup: Container;

  protected background: Sprite;

  protected freeRounds: TextField;

  protected roundsLabel: TextField;

  protected roundsAmount: TextField;

  protected isTriggerFreeSpin = false;

  protected backgroundFadeInAnimation: Tween;

  protected backgroundFadeOutAnimation: Tween;

  private bindedCallback = () => {
    this.backgroundFadeOutAnimation.start();
    this.visible = false;
    setIsPopupFreeRoundsOpened(false);
  };

  constructor() {
    super();
    this.freeRounds = this.initFreeRoundsText();
    this.roundsAmount = this.initRoundsAmount();
    this.roundsLabel = this.initRoundsLabel();
    this.background = this.initBackground();
    this.popup = this.initPopup();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();

    this.init();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.OPEN_POPUP_FREE_ROUNDS, this.show.bind(this));
  }

  protected init() {
    this.addChild(this.background);
    this.addChild(this.popup);
    this.visible = false;
  }

  protected initPopup(): Container {
    const container = new Container();
    const innerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.bgColor)
      .drawRoundedRect(0, 0, LAYOUT_OPTIONS.width, LAYOUT_OPTIONS.height, LAYOUT_OPTIONS.borderRadius)
      .endFill();
    innerBg.x = 1;
    innerBg.y = 1;
    const outerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.borderColor)
      .drawRoundedRect(
        0,
        0,
        LAYOUT_OPTIONS.width + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.height + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.borderRadius,
      )
      .endFill();
    const closeBtn = this.createButton();

    container.addChild(outerBg, innerBg, closeBtn, this.freeRounds.text, this.roundsAmount.text, this.roundsLabel.text);
    closeBtn.x = LAYOUT_OPTIONS.width / 2 - closeBtn.width / 2;
    closeBtn.y = LAYOUT_OPTIONS.height - (closeBtn.height * 3) / 2;

    return container;
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    sprite.alpha = 0.5;
    return sprite;
  }

  private initRoundsLabel() {
    const text = new TextField(i18n.t('freeRoundsLabel'), 100, 100, freeRoundsTextStyles);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(215, 57);
    return text;
  }

  private initRoundsAmount() {
    const text = new TextField('', 100, 100, { ...freeRoundsTextStyles, fontSize: 50 });
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(215, 115);
    return text;
  }

  private initFreeRoundsText() {
    const text = new TextField(i18n.t('freeRounds'), 100, 100, freeRoundsTextStyles);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(215, 175);

    return text;
  }

  private initBackGroundFadeInAnimation() {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation() {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public show(rounds: number): void {
    setIsPopupFreeRoundsOpened(true);
    this.roundsAmount.setText(`${rounds}`);
    this.visible = true;
    this.backgroundFadeInAnimation.start();
    if (setFreeRoundsTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      });
    }
    updateCoinValueFromFreeRoundBonus();
  }

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    this.background.position.set(width / 2, height / 2);

    if (width - 50 < LAYOUT_OPTIONS.width) {
      this.popup.scale.set(width / (LAYOUT_OPTIONS.width + 50));
    } else if (height - 100 < LAYOUT_OPTIONS.height) {
      this.popup.scale.set(height / (LAYOUT_OPTIONS.height + 100));
    } else {
      this.popup.scale.set(1);
    }
    this.popup.y = height / 2 - this.popup.height / 2;
    this.popup.x = width / 2 - this.popup.width / 2;
  }

  private createButton(): Container {
    const width = LAYOUT_OPTIONS.width / 3;
    const height = LAYOUT_OPTIONS.width / 8;
    const button = new Graphics()
      .beginFill(LAYOUT_OPTIONS.buttonColor)
      .drawRoundedRect(0, 0, width, height, LAYOUT_OPTIONS.borderRadius / 2)
      .endFill();
    const text = new TextField(i18n.t('close'), 100, 100, buttonTextStyle);
    text.text.anchor.set(0.5);
    text.text.position.set(width / 2, height / 2);

    button.interactive = true;
    button.on('pointertap', () => this.bindedCallback());
    button.cursor = 'pointer';
    button.addChild(text.text);

    return button;
  }
}

import { SlotId } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { Game } from '../../game';
import { GameMode } from '../../global.d';
import { setGameMode } from '../../gql';
import Animation from '../animations/animation';
import { ZOrderReelContainer } from '../components/layer/config';
import { layerReel } from '../components/layer/layer';
import { StrictSpine } from '../components/spine';

type SymbolAnimationNames = SpineInterface['symbol_all']['animations'];

export type ConvertIndex = Record<number, number>;

export const animationNameIndexByGameMode: Record<GameMode, ConvertIndex> = {
  [GameMode.BASE_GAME]: {
    [0]: 4,
    [1]: 3,
    [2]: 2,
    [3]: 1,
  },
  [GameMode.BUY_FEATURE]: {
    [0]: 4,
    [1]: 3,
    [2]: 2,
    [3]: 1,
  },
  [GameMode.FREE_SPINS]: {
    [0]: 5,
    [1]: 4,
    [2]: 3,
    [3]: 2,
    [4]: 1,
  },
};

export class DefaultSymbol {
  public slotId: SlotId;

  public spine: StrictSpine<'symbol_all'>;

  constructor(id: SlotId) {
    this.slotId = id;
    const spine = Game.getInstance().maker.spine('symbol_all');
    this.spine = spine;
    this.setSymbolSkin();
  }

  public setSymbolSkin(setSkinName?: SpineInterface['symbol_all']['skins']) {
    const skinName = (setSkinName ?? this.slotId.toLowerCase()) as SpineInterface['symbol_all']['skins'];
    this.spine.skeleton.setSkinByName(skinName);
    this.spine.skeleton.setToSetupPose();
  }

  public startStopAnimation(slotIndex: number) {
    const animationName = `step${this.getAnimationNameIndex(slotIndex)}_stop` as SymbolAnimationNames;
    this.spine.state.setAnimation(0, animationName);
  }

  public startInAnimation(slotIndex: number) {
    const animationName = `step${this.getAnimationNameIndex(slotIndex)}_in` as SymbolAnimationNames;
    this.spine.state.setAnimation(0, animationName);
  }

  public startWinAnimation(slotIndex: number) {
    const animationName = `step${this.getAnimationNameIndex(slotIndex)}_win` as SymbolAnimationNames;
    this.spine.state.setAnimation(0, animationName);
  }

  public startLostAnimation(slotIndex: number) {
    const animationName = `step${this.getAnimationNameIndex(slotIndex)}_lost` as SymbolAnimationNames;
    this.spine.state.setAnimation(0, animationName);
  }

  public getSpineAnimation(slotIndex: number, animationAbbv: 'stop' | 'in' | 'lost' | 'win'): Animation {
    return this.spine.getAnimation(
      0,
      `step${this.getAnimationNameIndex(slotIndex)}_${animationAbbv}` as SymbolAnimationNames,
    );
  }

  private getAnimationNameIndex = (slotIndex: number) => {
    // The numbers in the animation name and data are reversed
    const animationNameIndex = animationNameIndexByGameMode[setGameMode()]!;
    return animationNameIndex[slotIndex];
  };
}

export class ScatterSymbol extends DefaultSymbol {
  constructor(id: SlotId) {
    super(id);
    this.spine = Game.getInstance().maker.spine('symbol_scatter');
    this.spine.parentLayer = layerReel;
    this.spine.zOrder = ZOrderReelContainer.SCATTER_REEL;
  }

  public override setSymbolSkin(setSkinName?: SpineInterface['symbol_all']['skins']) {
    if (setSkinName === 'default') {
      this.spine.visible = false;
    } else {
      this.spine.visible = true;
    }
  }
}

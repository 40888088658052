import { Group, Layer } from '@pixi/layers';
import { Application } from 'pixi.js';

export const layerBackGround = new Layer(new Group(0));
export const layerReel = new Layer(new Group(11));
export const layerWin = new Layer(new Group(40));
export const layerBanner = new Layer(new Group(51));
export const layerUI = new Layer(new Group(53));
export const layerAllScreen = new Layer(new Group(70));
export const layerTransition = new Layer(new Group(99));

export const InitLayer = (application: Application): void => {
  application.stage.addChild(layerBackGround);
  layerReel.group.enableSort = true;
  application.stage.addChild(layerReel);
  application.stage.addChild(layerWin);

  layerBanner.group.enableSort = true;
  application.stage.addChild(layerBanner);
  application.stage.addChild(layerUI);
  application.stage.addChild(layerAllScreen);
  application.stage.addChild(layerTransition);
};
